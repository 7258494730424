<app-loading></app-loading>
<div class="page-wraper">
	<!-- header -->
    <app-header1 [data]="profile"></app-header1>
    <!-- header END -->
	<app-page-banner [data]="page_banner"></app-page-banner>
	<app-social-bar></app-social-bar>
	
	<div class="content-body">
		<div class="content-body-inner">
			<div class="section-full">
				<div class="section-head m-b50">
					<h2 class="title text-uppercase">Get to Know Latest  <span class="text-gradient">Articles</span></h2>
				</div>
				<div class="row">
					<p *ngIf="arts.length === 0">
						No Articles found
					</p>
					<div *ngFor="let art of arts; let i = index" class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="{{art.title}}/{{art.id}}"><img [src]="art.image" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> {{art.date}} </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="{{art.title}}/{{art.id}}">{{art.title}}</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p class="openP" [innerHTML]="art.opening"></p>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic2.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 22, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">The Influence Of Blog</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic3.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 21, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">Seven Easy Rules</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.6s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic4.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 20, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">10 Quick Tips For Blog</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.4s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic5.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 19, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">The Ultimate Revelation</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.2s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic6.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 18, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">5 Reasons Why People Like</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.8s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic7.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 17, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">The Truth About Developer</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.6s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic8.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 16, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">Own Blog Developer For Free</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.4s">
						<div class="blog-post">
							<div class="dlab-post-media">
								<a routerLink="blog-single.html"><img src="assets/images/blog/grid/pic9.jpg" alt=""></a>
							</div>
							<div class="dlab-post-info">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> february 15, 2020 </li>
									</ul>
								</div>
								<div class="dlab-post-title">
									<h5 class="post-title"><a routerLink="blog-single.html">10 Common Myths About</a></h5>
								</div>
								<div class="dlab-post-text">
								   <p>Ex audire suavitate has, ei quodsi tacimates sapientem sed, pri zril ubique ut.</p>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</div>