import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { arts } from 'src/assets/blog/arts';

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.css']
})
export class BlogSingleComponent implements OnInit, OnDestroy {
  
  profile : any = {
                  logo:"logo-black.png",
                  profile:"photographer"
            };

  page_banner : any = {
        title:"Blog Article",
        profile:"photographer",
  };

  _des: Subject<any> = new Subject();

  arts: any[] = arts;

  article: any;

  constructor(
    private _acR: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._acR.params.pipe(
      takeUntil(this._des)
    ).subscribe(
      (res) => {
        console.log(res);
        if(res.id){
          arts.forEach((it) => {
            if(it.id == res.id){
              this.article = it;
            }
          })
          console.log(this.article);
          // this.page_banner.title = this.article.title
        }
      }
    )
  }

  ngOnDestroy(): void {
    this._des.next()
    this._des.complete();
  }

}
