<div class="row">
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.1s" *ngFor="let client of data">
    <div class="client-logo"><img src="assets/images/client-logo/{{client.logo}}" alt=""/></div>
  </div>
  
  <!-- <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
    <div class="client-logo"><img src="assets/images/client-logo/logo3.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
    <div class="client-logo"><img src="assets/images/client-logo/logo1.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
    <div class="client-logo"><img src="assets/images/client-logo/logo2.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
    <div class="client-logo"><img src="assets/images/client-logo/logo6.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
    <div class="client-logo"><img src="assets/images/client-logo/logo4.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.7s">
    <div class="client-logo"><img src="assets/images/client-logo/logo5.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
    <div class="client-logo"><img src="assets/images/client-logo/logo3.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.9s">
    <div class="client-logo"><img src="assets/images/client-logo/logo1.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.0s">
    <div class="client-logo"><img src="assets/images/client-logo/logo2.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.1s">
    <div class="client-logo"><img src="assets/images/client-logo/logo6.jpg" alt=""/></div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.2s">
    <div class="client-logo"><img src="assets/images/client-logo/logo4.jpg" alt=""/></div>
  </div> -->
</div>