<div class="timeline" *ngFor="let timeline of data">
  <div class="box-left">
    <h4 class="title text-primary">{{timeline.year}}</h4>
    <span>{{timeline.organization_name}}</span>
  </div>
  <div class="box-right">
    <h6 class="title text-uppercase">{{timeline.title}}</h6>
    <p>{{timeline.description}}</p>
  </div>
</div>


<!-- <div class="timeline">
  <div class="box-left">
    <h4 class="title text-primary">2014</h4>
    <span>University of Studies</span>
  </div>
  <div class="box-right">
    <h6 class="title text-uppercase">Graphic Design</h6>
    <p>Maecenas finibus nec sem ut imperdiet. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis ante.</p>
  </div>
</div>
<div class="timeline">
  <div class="box-left">
    <h4 class="title text-primary">2012</h4>
    <span>University of Studies</span>
  </div>
  <div class="box-right">
    <h6 class="title text-uppercase">Senior Ui/Ux Designer</h6>
    <p>Maecenas finibus nec sem ut imperdiet. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis ante.</p>
  </div>
</div>
<div class="timeline">
  <div class="box-left">
    <h4 class="title text-primary">2011</h4>
    <span>University of Studies</span>
  </div>
  <div class="box-right">
    <h6 class="title text-uppercase">Junior Ui/Ux Designer</h6>
    <p>Maecenas finibus nec sem ut imperdiet. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis ante.</p>
  </div>
</div> -->