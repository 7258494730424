import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogSingleComponent } from './pages/blog-single/blog-single.component';
import { BlogComponent } from './pages/blog/blog.component';

import { WebDeveloperAboutUsComponent } from './web-developer/web-developer-about-us/web-developer-about-us.component';
import { WebDeveloperContactUsComponent } from './web-developer/web-developer-contact-us/web-developer-contact-us.component';
import { WebDeveloperIndexComponent } from './web-developer/web-developer-index/web-developer-index.component';
import { WebDeveloperPortfolioComponent } from './web-developer/web-developer-portfolio/web-developer-portfolio.component';
import { WebDeveloperResumeComponent } from './web-developer/web-developer-resume/web-developer-resume.component';

const routes: Routes = [
    {path: '', component: WebDeveloperIndexComponent},
    {path: 'about-me', component: WebDeveloperAboutUsComponent},
    {path: 'contact-me', component: WebDeveloperContactUsComponent},
    {path: 'portfolio', component: WebDeveloperPortfolioComponent},
    {path: 'resume', component: WebDeveloperResumeComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog/:title/:id', component: BlogSingleComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
