<div class="row sp40">
  <div class="col-xl-6 col-lg-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s" *ngFor="let service of data">
    <div class="icon-bx-wraper service-box m-b40">
      <div class="icon-content">
        <h4 class="dlab-tilte text-uppercase">{{service.title}}</h4>
        <p>{{service.description}}</p>
      </div>
      <i class="ti-desktop bg-icon"></i>
    </div>
  </div>
  
  <!-- <div class="col-xl-6 col-lg-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
    <div class="icon-bx-wraper service-box m-b40">
      <div class="icon-content">
        <h4 class="dlab-tilte text-uppercase">Engagement photography</h4>
        <p>Praesent ut tortor consectetur, semper sapien non, lacinia augue. Aenean arcu libero, facilisis et nisi non, tempus faucibus tortor. Mauris vel nulla aliquam.</p>
      </div>
      <i class="ti-desktop bg-icon"></i>
    </div>
  </div>
  <div class="col-xl-6 col-lg-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
    <div class="icon-bx-wraper service-box m-lg-b40">
      <div class="icon-content">
        <h4 class="dlab-tilte text-uppercase">Commercial photography</h4>
        <p>Praesent ut tortor consectetur, semper sapien non, lacinia augue. Aenean arcu libero, facilisis et nisi non, tempus faucibus tortor. Mauris vel nulla aliquam.</p>
      </div>
      <i class="ti-desktop bg-icon"></i>
    </div>
  </div>
  <div class="col-xl-6 col-lg-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
    <div class="icon-bx-wraper service-box">
      <div class="icon-content">
        <h4 class="dlab-tilte text-uppercase">Personal photography</h4>
        <p>Praesent ut tortor consectetur, semper sapien non, lacinia augue. Aenean arcu libero, facilisis et nisi non, tempus faucibus tortor. Mauris vel nulla aliquam.</p>
      </div>
      <i class="ti-desktop bg-icon"></i>
    </div>
  </div> -->
</div>