<div class="row">
  <div class="col-xl-3 col-lg-6 col-sm-6 col-6 m-lg-b30 m-xs-b15 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s" *ngFor="let funFacts of data">
    <div class="counter-style-1">
      <div class="">
        <h2 class="text-gradient"><span class="counter text-gradient">{{funFacts.number}}</span> +</h2>
      </div>
      <h6 class="counter-text">{{funFacts.title}}</h6>
    </div>
  </div>
  <!-- <div class="col-xl-3 col-lg-6 col-sm-6 col-6 m-lg-b30 m-xs-b15 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
    <div class="counter-style-1">
      <div class="">
        <h2 class="counter text-gradient">7582</h2>
      </div>
      <h6 class="counter-text">WORKING HOURS</h6>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
    <div class="counter-style-1">
      <div class="">
        <h2 class="counter text-gradient">120</h2>
      </div>
      <h6 class="counter-text">AWARDS WON</h6>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
    <div class="counter-style-1">
      <div class="">
        <h2 class="counter text-gradient">2000</h2>
      </div>
      <h6 class="counter-text">PROJECTS COMPLETED</h6>
    </div>
  </div> -->
</div>