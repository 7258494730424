<app-loading></app-loading>
<div class="page-wraper">
	<!-- header -->
    <app-header1 [data]="profile"></app-header1>
    <!-- header END -->
	<app-page-banner [data]="page_banner"></app-page-banner>
  	<app-social-bar></app-social-bar>
	
	<div class="content-body">
		<div class="content-body-inner">
			
			<!-- About Info -->
			<div class="section-full">
				<div class="section-head">
					<h2 class="title text-uppercase">How Can I <span class="text-gradient">Help You</span>?</h2>
					<div class="noteStyle">
						I'm open to new projects
					</div>
				</div>
				<div class="contact-form">
					<form (ngSubmit)="sendMSG()" [formGroup]="contactFrom" class="dzForm dezPlaceAni">
						
						<!-- <input type="hidden" value="Contact" name="dzToDo" > -->
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group">
									<i class="las la-user text-gradient"></i>
									<!-- <label>Your Name</label> -->
									<input formControlName="name" placeholder="Your Name" name="dzName" type="text" required class="form-control ">
								</div>
								<div *ngIf="!(inputHasError('name'))" class="dzFormMsg errorStyle">
									Name is required.
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<i class="las la-envelope-open text-gradient"></i>
									<!-- <label>Your Email Address</label> -->
									<input formControlName="email" placeholder="Your Email Address" name="dzEmail" type="email" class="form-control" required >
								</div>
								<div *ngIf="!(inputHasError('email'))" class="dzFormMsg errorStyle">
									Email is required & must be valid email address.
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<i class="las la-phone-volume text-gradient"></i>
									<!-- <label>Phone Number</label> -->
									<input formControlName="phone" placeholder="Phone Number" name="dzPhone" type="tel" required class="form-control ">
								</div>
								<div *ngIf="!(inputHasError('phone'))" class="dzFormMsg errorStyle">
									Phone number is required & must be valid phone number.
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<i class="las la-trademark text-gradient"></i>
									<!-- <label>Your Name</label> -->
									<input formControlName="company" placeholder="Your company" name="dzName" type="text" class="form-control ">
								</div>
							</div>
							<div class="col-sm-12">
								<div class="form-group">
									<i class="las la-file-alt text-gradient"></i>
									<!-- <label>Subject</label> -->
									<input formControlName="subject" placeholder="Subject" name="dzSubject" type="text" class="form-control" required >
								</div>
								<div *ngIf="!(inputHasError('subject'))" class="dzFormMsg errorStyle">
									Subject is required & must be 6 characters at least.
								</div>
							</div>
							<div class="col-sm-12">
								<div class="form-group">
									<i class="las la-envelope-open text-gradient"></i>
									<!-- <label>Your Message</label> -->
									<textarea formControlName="msg" placeholder="Your Message" name="dzMessage" rows="4" class="form-control" required ></textarea>
								</div>
								<div *ngIf="!(inputHasError('msg'))" class="dzFormMsg errorStyle">
									Message is required & must be 10 characters at least.
								</div>
							</div>
							<!-- <div class="col-md-12 m-b20 m-xs-b0 ">
								<div class="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
								<input class="form-control d-none" style="display:none;" data-recaptcha="true" required data-error="Please complete the Captcha">
							</div> -->
							<div class="col-md-12">
								<button *ngIf="!(loading | async) && showButton" name="submit" type="submit" value="Submit" class="site-button button-gradient button-md radius-no">Send Message</button>
								<img *ngIf="(loading | async)" style="width: 20%" src="assets/images/loading.gif" alt="">
								<div style="margin-top: 15px;" class="dzFormMsg {{msgStyle}}">
									{{msg}}
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			
			<!-- Contact Info -->
			<div class="section-full content-inner">
				<app-icon-box></app-icon-box>
			</div>
			
			<!-- Map -->
			<!-- <div class="section-full maps">
				<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14445.871196953145!2d75.8417313!3d25.153677599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1580295328844!5m2!1sen!2sin" style="border:0; width: 100%;" allowfullscreen=""></iframe>
			</div> -->
			
		</div>
	</div>
</div>