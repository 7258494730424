<app-loading></app-loading>
<div class="page-wraper">
	<!-- header -->
    <app-header1 [data]="profile"></app-header1>
    <!-- header END -->
    <app-page-banner [data]="page_banner"></app-page-banner>
    <app-social-bar></app-social-bar>
	
	<div class="content-body">
		<div class="content-body-inner">
			
			<!-- About Info -->
			<div class="section-full">
				<div class="row">
					<div class="col-xl-6 col-lg-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
						<div class="sticky-top m-lg-b30">
							<img src="assets/images/about/{{member.image}}" alt=""/>
						</div>
					</div>
					<div class="col-xl-6 col-lg-12 m-b30 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
						<div class="section-head">
							<h2 class="title text-uppercase">I’ Am <br/><span class="text-gradient">{{member.name}}</span></h2>
							<p class="m-b0" [innerHTML]="member.about_me"></p>
						</div>						
						<app-line [data]="skills"></app-line>
						<div class="m-t50 m-xs-t30">
							<a routerLink="/contact-me" class="site-button button-gradient button-md radius-no">Hire me</a>
							<a routerLink="/about-me" class="site-button white button-md radius-no">My services</a>
						</div>
					</div>
				</div>
			</div>
			<div class="section-full content-inner-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
				<div class="section-head">
					<h2 class="title text-uppercase">KNOWLEDGE</h2>
				</div>
				<app-tags [data]="knowledge"></app-tags>
			</div>
			<!-- <div class="section-full content-inner-1">
				<div class="resume-tag">
					<div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
						<strong>BIRTHDAY :</strong>
						<span>{{member.dob}}</span>
					</div>
					<div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
						<strong>LOCATION :</strong>
						<span>{{member.address}}</span>
					</div>
					<!-- <div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
						<strong>INTERESTS :</strong>
						<span>{{member.intrests}}</span>
					</div> -->
					<!-- <div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
						<strong>STUDY :</strong>
						<span>{{member.study}}</span>
					</div> -->
					<!-- <div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.0s">
						<strong>AGE :</strong>
						<span>{{member.age}}</span>
					</div> -->
					<!-- <div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.2s">
						<strong>DEGREE :</strong>
						<span>{{member.highes_degree}}</span>
					</div> -->
					<!-- <div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.4s">
						<strong>MAIL :</strong>
						<span>{{member.email}}</span>
					</div>
					<div class="resume-tag-btn wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.6s">
						<strong>PHONE :</strong>
						<span>{{member.phone}}</span>
					</div>
				</div>
			</div> --> 
			<!-- About Service -->
			<div class="section-full content-inner-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
				<div class="section-head">
					<h2 class="title text-uppercase">EXPERIENCE</h2>
				</div>
				<app-timeline  [data]="experience"></app-timeline>
			</div>
			<div class="section-full content-inner-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
				<div class="section-head">
					<h2 class="title text-uppercase">EDUCATION</h2>
				</div>
				<app-timeline  [data]="education"></app-timeline>
				
			</div>
			<div class="section-full content-inner-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
				<div class="section-head">
					<h2 class="title text-uppercase">LANGUAGES</h2>
				</div>
				<app-circle [data]="language_known"></app-circle>
			</div>
			
			<div class="section-full content-inner-1">
				<app-icon-box></app-icon-box>
			</div>
		</div>
	</div>
</div>


<script>
$(function(){

    // Remove svg.radial-progress .complete inline styling
    $('svg.radial-progress').each(function( index, value ) { 
        $(this).find($('circle.complete')).removeAttr( 'style' );
    });

    // Activate progress animation on scroll
    $(window).scroll(function(){
        $('svg.radial-progress').each(function( index, value ) { 
            // If svg.radial-progress is approximately 25% vertically into the window when scrolling from the top or the bottom
            if ( 
                $(window).scrollTop() > $(this).offset().top - ($(window).height() * 0.75) &&
                $(window).scrollTop() < $(this).offset().top + $(this).height() - ($(window).height() * 0.25)
            ) {
                // Get percentage of progress
                percent = $(value).data('percentage');
                // Get radius of the svg's circle.complete
                radius = $(this).find($('circle.complete')).attr('r');
                // Get circumference (2πr)
                circumference = 2 * Math.PI * radius;
                // Get stroke-dashoffset value based on the percentage of the circumference
                strokeDashOffset = circumference - ((percent * circumference) / 100);
                // Transition progress for 1.25 seconds
                $(this).find($('circle.complete')).animate({'stroke-dashoffset': strokeDashOffset}, 1250);
            }
        });
    }).trigger('scroll');

});
</script>