
<div class="progress-box" *ngFor="let skills of data">
  <div class="progress-title">{{skills.title}}<span>{{skills.score}}%</span></div>
  <div class="progress">
     <div class="progress-bar wow slideInLeft" data-wow-duration="1s" data-wow-delay="0.5s" role="progressbar" style="width: {{skills.score}}%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

<!-- <div class="progress-box">
  <div class="progress-title">photography<span>90%</span></div>
  <div class="progress">
     <div class="progress-bar wow slideInLeft" data-wow-duration="1.2s" data-wow-delay="0.7s" role="progressbar" style="width: 90%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>
<div class="progress-box">
  <div class="progress-title">editing <span>45%</span></div>
  <div class="progress">
     <div class="progress-bar wow slideInLeft" data-wow-duration="1.4s" data-wow-delay="0.9s" role="progressbar" style="width: 45%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>
<div class="progress-box">
  <div class="progress-title">Team working<span>67%</span></div>
  <div class="progress">
     <div class="progress-bar wow slideInLeft" data-wow-duration="1.6s" data-wow-delay="1.2s" role="progressbar" style="width: 67%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div> -->