import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-developer-about-us',
  templateUrl: './web-developer-about-us.component.html',
  styleUrls: ['./web-developer-about-us.component.css']
})
export class WebDeveloperAboutUsComponent implements OnInit {

  whatsAppMsg: string = encodeURI("Hi Abdo. How are you? I took a look at your website, and I'm interested in...");
  
  profile : any = {
                  logo:"logo-black-5.png",
                  profile:"web-developer"
            };

  page_banner : any = {
        title:"About me",
        profile:"web-developer",
  };
            
  page_info : any = {
                title:"WHO AM I",
                image:"pic1.jpg",
                description:'Innovative serial <span class="title text-uppercase">entrepreneur</span> & savvy <span class="title text-uppercase">businessman</span>. Influential & witty business <span class="title text-uppercase">leader</span>. Visionary product <span class="title text-uppercase">developer</span> with deep education in research & analytics. <span class="title text-uppercase">Growth hacker</span> & digital marketing expert with success records. Experienced in all aspects of business formation, operation, finance, and management.<br/>Effective <span class="title text-uppercase">manager</span> & motivator who identifies & leverages assets in teammates to reach organizational goals. A relentless optimist who believes there is no failure, only feedback. Action-oriented with a strong ability to communicate effectively with <span class="title text-uppercase">technology</span>, executive, & business audiences.'
          };

  member : any = {
        image:"",
        logo:"",
        designation:"",
        age:new Date().getFullYear() - 1993,
        dob:"",
        residence:"Israel",
        address:"Tel Aviv / Haifa / Iksal",
        email:"abdoq@abdoqassem.com",
        phone:"+972 50 686 8899",
        // skype:"ALEX.SMITH",
        whatsapp:"+972 50 686 8899",
        about_me:"",
        intrests:"",
        name:"",
        study:"",
        highes_degree:"",
        profile:"web-developer"
  };
  
          
  services : any = [
                  {
                    title:"Software",
                    description:"Software consulting services aim to maximize the ROI of a software development initiative through effective planning, execution, and management of new software development projects or setting to rights an ongoing project. Providing all-round software consulting, I help clients from various industries keep end-to-end software development fast and economically sound and deliver high-quality software solutions.",
                  },
                  {
                    title:"Innovative R&D solutions",
                    description:"An expert in working with science-intensive projects using the most innovative technologies and tools. My strong connections and long-term collaboration with leading global science institutions and research laboratories can effectively transform your innovative ideas into commercial products. I'm action-oriented with a strong ability to communicate effectively with technology, executive, and business audiences.",
                  },
                  {
                    title:"Marketing",
                    description:"Digital marketing best practices are constantly evolving, and can’t-miss strategies can quickly lose their impact. The most successful brands understand that digital marketing is a 24/7/365 effort. If you’re not keeping up with the latest best practices, the competition is passing you by.Whether you need to build an entire marketing strategy from scratch or refine your approach,  my marketing consulting services can help update your marketing efforts for the best results possible.",
                  },
                  {
                    title:"Business Development",
                    description:"Is your business development process well-defined, or is your firm being overshadowed by the competition? My Business Development Process Improvement services are designed to help your firm grow and expand. I take a hard look at your numbers – prospects, proposals, close ratios, ROI – to identify business development gaps and opportunities. I work closely with my clients and concretely participate in the development of their operative initiatives.",
                  },
                  {
                    title: "Global Wealth Management",
                    description: "I'm a strong deal hunter, and my team specializes in customized cross-border financial advice and wealth management. We carefully analyze your circumstances and objectives to develop comprehensive strategies to address your unique set of needs—my objective of providing best practices in global investment management to families and people in the business. Working closely with my clients, we tailor the investment strategies to their specific needs, with special attention to their ongoing financial and tax planning considerations."
                  },
                  {
                    title: "Networking",
                    description: "Whether you're seeking to invest, raise funds, cooperation, local partner, or joint fund, I'm here to find you the best player in the field who can give you the best chances to achieve your plans. My tremendous network of VCs, investors, organizations, and angels accommodates me to find you the ideal companion that will open the success gateways for you."
                  }
              ];
  fun_facts : any = [
                  {
                    number:"50",
                    title:"HAPPY CLIENTS",
                  },
                  {
                    number:"200",
                    title:"PROJECTS COMPLETED",
                  },
                  {
                    number:"36",
                    title:"PROJECT DESIGNED",
                  },
                  {
                    number:"500",
                    title:"RECOMMENDATIONS",
                  },
              ];
  testimonials : any = [
                  {
                    client_name:"Steve Tylor",
                    image:"pic1.jpg",
                    designation:"Developer",
                    description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.",
                  },
                  {
                    client_name:"David Matin",
                    image:"pic3.jpg",
                    designation:"Designer",
                    description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.",
                  },
                  {
                    client_name:"Monica Rodriguez",
                    image:"pic2.jpg",
                    designation:"Manager",
                    description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.",
                  },
                  {
                    client_name:"Steve Tylor",
                    image:"pic1.jpg",
                    designation:"Developer",
                    description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.",
                  },
                  {
                    client_name:"David Matin",
                    image:"pic3.jpg",
                    designation:"Designer",
                    description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.",
                  },
                  {
                    client_name:"Monica Rodriguez",
                    image:"pic2.jpg",
                    designation:"Manager",
                    description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.",
                  },
              ];
  our_clients : any = [
                  {
                    client_title:"",
                    logo:"logo5.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo3.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                   client_title:"",
                    logo:"logo1.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo2.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo6.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo4.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo5.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo6.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo1.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo2.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo6.jpg",
                    link:"javascript:void(0);",
                  },
                  {
                    client_title:"",
                    logo:"logo4.jpg",
                    link:"javascript:void(0);",
                  },
              ];
              
  constructor() { }

  ngOnInit(): void {
    // console.log(this.whatsAppMsg)
  }

}
