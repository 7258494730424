<div class="copyright-fixed"><a href="javascript:void(0);">Abdo Qassem</a> © {{curYear}} All rights reserved.</div>


<!-- <div id="dzSwitcher" class="styleswitcher" style="left: -220px;">
   <div class="switcher-btn-bx"> <a class="switch-btn closed"> <span class="fa fa-cogs fa-lg"></span> </a></div>
   <div class="styleswitcher-inner">
      <a href="javascript:void(0);" class="dez-page site-button button-sm" onclick="deleteAllCookie();">Delete All Cookie</a> 
      <div class="theme-box">
         <h5 class="switcher-title"><span>Theme</span></h5>
         <h6 class="switcher-title">Standard</h6>
         <ul val="themeFullColor" class="color-skins-db theme-panel-save">
            <li> <a class="theme-skin skin-1" href="#" dir="css/skin/skin-1" logo="assets/images/logo.png"></a></li>
            <li> <a class="theme-skin skin-2" href="#" dir="css/skin/skin-2" logo="assets/images/logo-2.png"></a></li>
            <li> <a class="theme-skin skin-3" href="#" dir="css/skin/skin-3" logo="assets/images/logo-3.png"></a></li>
            <li> <a class="theme-skin skin-4" href="#" dir="css/skin/skin-4" logo="assets/images/logo-4.png"></a></li>
            <li> <a class="theme-skin skin-5" href="#" dir="css/skin/skin-5" logo="assets/images/logo-5.png"></a></li>
            <li> <a class="theme-skin skin-6" href="#" dir="css/skin/skin-6" logo="assets/images/logo-6.png"></a></li>
            <li> <a class="theme-skin skin-7" href="#" dir="css/skin/skin-7" logo="assets/images/logo-7.png"></a></li>
            <li> <a class="theme-skin skin-8" href="#" dir="css/skin/skin-8" logo="assets/images/logo-8.png"></a></li>
         </ul>
      </div>
   </div>
</div> -->
<!-- <div class="switcher-demo" style="left: 0px;">
   <div class="switcher-btn-bx"><a class="switch-demo-btn open"> Check Demo </a></div>
   <div class="styleswitcher-inner">
      <div class="switch-demo-bx">
         <ul class="switch-demo">
            <li> <a routerLink="/index-photographer"> <img src="assets/images/demo/01-index-photographer.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-business-man"> <img src="assets/images/demo/02-index-business-man.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-modal"> <img src="assets/images/demo/03-index-modal.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-doctor"> <img src="assets/images/demo/04-index-doctor.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-security"> <img src="assets/images/demo/05-index-security.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-health-coach"> <img src="assets/images/demo/06-index-health-coach.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-electrician"> <img src="assets/images/demo/07-index-electrician.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-blogger"> <img src="assets/images/demo/08-index-blogger.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-web-developer"> <img src="assets/images/demo/09-index-web-developer.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-swimmer"> <img src="assets/images/demo/10-index-swimmer.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-dance-trainer"> <img src="assets/images/demo/11-index-dance-trainer.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-news-anchor"> <img src="assets/images/demo/12-index-news-anchor.jpg" alt=""> </a></li>
            <li> <a routerLink="/index-youtube"> <img src="assets/images/demo/13-index-youtube.jpg" alt=""> </a></li>
         </ul>
      </div>
   </div>
</div> -->