<header class="site-header header-transparent header2">
<!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-xl">
        <div class="main-bar clearfix">
            <div class="container-fluid clearfix">
                <!-- website logo -->
                <!-- <div class="logo-header">
                    <a routerLink="/index-{{data.profile}}"><img src="assets/images/{{data.logo}}" alt=""></a>
                </div> -->
      <!-- extra nav -->
                <div class="extra-nav">
                    <div class="extra-cell">
                        <ul>
                            <li><a routerLink="/contact-me"><span>Contact me?</span></a></li>
                            <li><a href="javascript:void(0);" class="menu-btn active"><span>Menu</span><div class="menu"><span></span><span></span><span></span></div></a></li>
                        </ul>
                    </div>
                </div>
                <!-- main nav -->
                <div class="header-nav icon-menu active">
                    <ul class="nav navbar-nav">	
                    

                        <li *ngFor="let menuData of menu_item" [routerLinkActiveOptions]="{exact: menuData.link == '' ? true : false}" routerLinkActive="active" ><a routerLink="/{{menuData.link}}"><i class="{{menuData.class}}"></i></a></li>

                    
                    </ul>	
                </div>
            </div>
        </div>
    </div>
    <!-- main header END -->
</header>