import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-developer-resume',
  templateUrl: './web-developer-resume.component.html',
  styleUrls: ['./web-developer-resume.component.css']
})
export class WebDeveloperResumeComponent implements OnInit {
  
  profile : any = {
                  logo:"logo-black-5.png",
                  profile:"web-developer"
            };

  page_banner : any = {
        title:"Resume",
        profile:"web-developer",
  };
  
  member : any = {
        image:"abdoqassem.png",
        logo:"",
        designation:"",
        age:new Date().getFullYear() - 1993,
        dob:"31.03.1993",
        residence:"Israel",
        address:"Tel Aviv",
        email:"abdoq@abdoqassem.com",
        phone:"+972 58 363 6564",
        whatsapp:"",
        about_me:'Hi! My name is <span class="text-primary">Abdo Qassem</span>. I am an innovative serial <span class="title text-uppercase">entrepreneur</span> & savvy <span class="title text-uppercase">businessman</span>. Influential & witty business <span class="title text-uppercase">leader</span>. Visionary product <span class="title text-uppercase">developer</span> with deep education in research & analytics. <span class="title text-uppercase">Growth hacker</span> & digital marketing expert with success records. Experienced in all aspects of business formation, operation, finance, and management.<br/>Effective <span class="title text-uppercase">manager</span> & motivator who identifies & leverages assets in teammates to reach organizational goals. A relentless optimist who believes there is no failure, only feedback. Action-oriented with a strong ability to communicate effectively with <span class="title text-uppercase">technology</span>, executive, & business audiences.',
        intrests:"PLAYSTATION, READING",
        name:"Abdo Qassem",
        study:"UNIVERSITY OF CHICAGO",
        highes_degree:"MASTER",
        profile:"web-developer"
  };
  
  skills : any = [
      {
        title:"Communication",
        score:"92",
      },
      {
        title:"negotiation",
        score:"78",
      },
      {
        title:"management",
        score:"95",
      },
      {
        title:"Networking",
        score:"90",
      },

];
  
  education : any = [
                  {
                    title:"Digital Marketing",
                    year:"2016",
                    organization_name:"",
                    description:"HackerU - Hafia",
                  },
                  {
                    title:"Full-stack Developer",
                    year:"2013",
                    organization_name:"",
                    description:"HackerU – Nazareth",
                  },
                  {
                    title:"Bachelor of Science: Electric engineer",
                    year:"2012",
                    organization_name:"",
                    description:"Technion - Israel Institute of Technology",
                  },
                  {
                    title:"Physics 5 - Electronic 10",
                    year:"2011",
                    organization_name:"",
                    description:"St. Joseph Seminary & High School",
                  },
              ];
  experience : any = [
                  {
                    title:"Team Leader",
                    year:"2021",
                    organization_name:"Zigit",
                    description:"The perfect digital solutions, just your way",
                  },
                  {
                    title:"Founder",
                    year:"2020",
                    organization_name:"ALPhoenix",
                    description:"Your Global Business Partner for SUCCESS!",
                  },
                  {
                    title:"Manager",
                    year:"2020",
                    organization_name:"Startup Club",
                    description:"Responsible for growing the Startup Club community online and offline in Israel and for managing Startup Club events.",
                  },
                  {
                    title:"Consultant",
                    year:"2018",
                    organization_name:"Free lance",
                    description:"Advise clients on how to configure large applications, write code, or fix bugs & customize software systems for specific tasks or industries. Analyze company computer systems to determine how software can make processes more efficient.",
                  },
                  {
                    title:"Founder",
                    year:"2016",
                    organization_name:"PureTech",
                    description:"Outsourcing & software solutions & digital marketing company",
                  },
                  {
                    title:"Co-Founder & CTO",
                    year:"2015",
                    organization_name:"ShareShipper",
                    description:"Launching startup (Marketplace) that works to reduce global shipping costs across the seas by sharing empty spaces on containers.",
                  },
                  {
                    title:"Leading Developer",
                    year:"2014",
                    organization_name:"DeekSleem",
                    description:"Leading developer in the company that maintains servers & online projects & building websites for company clients.",
                  },
                  {
                    title:"Full-stack developer",
                    year:"2014",
                    organization_name:"Free lance",
                    description:"Full-stack developer that works with marketing companies as a free-lance on developing & maintaining websites/landing pages/API connections.",
                  },
                  {
                    title:"Pre-university assessment tutor (Psychometric) tutor",
                    year:"2012",
                    organization_name:"Sigma – Nazareth",
                    description:"",
                  },
              ];
  language_known : any = [
                  {
                    title:"English",
                    score:"90",
                  },
                  {
                    title:"Arabic",
                    score:"95",
                  },
                  {
                    title:"Hebrew",
                    score:"90",
                  },
                  {
                    title:"Russian",
                    score:"55",
                  },

              ];
  
  knowledge: any = [
              "Software",
              "Digitalization",
              "Back-end",
              "Front-end",
              "Cyber",
              "API",
              "Cloud",
              "ML",
              "VR",
              "Security",
              "Diagrams",
              "Marketing",
              "Branding",
              "SEO",
              "PPC",
              "Copywriting",
              "Planning",
              "Negotiation skills",
              "Creative",
              "Innovator",
              "Wealth Management",
              "Networking",
              "Raise Funds"
              ];


  constructor() { }

  ngOnInit(): void {
  }

}
