<!-- <app-loading></app-loading> -->
<div class="page-wraper">
	<!-- header -->
    <app-header1 [data]="profile"></app-header1>
    <!-- header END -->
	<div class="banner-section coad-bnr">
		<canvas id="c" class="coad-bg"></canvas>
		<div class="banner-inner bnr-left">
			<h3 class="sub-title">I’ Am <span class="text-gradient">Abdo Qassem</span></h3>
			<h2 class="title">Entrepreneur <br>& Innovations</h2>
			<div class="d-flex justify-content-between">
				<a routerLink="/resume" class="site-button outline button-md outline-half mr-2">Check My Cv</a> 
				<a routerLink="/blog" class="site-button outline button-md outline-half">Blog</a> 
			</div>
			<img src="assets/images/background/abdoqassem.png" class="main-content" alt=""/>			
		</div>
		<app-social-bar></app-social-bar>
		<app-copywrite></app-copywrite>
		<app-user-card [data]="member"></app-user-card>
	</div>
</div>