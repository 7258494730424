<!-- <div class="testimonial-one owl-carousel-1 dots-style-1 owl-theme"> -->

<owl-carousel-o [options]="customOptions" class="testimonial-one1 owl-carousel-1 dots-style-1 owl-theme owl-none">
  <ng-template carouselSlide *ngFor = "let testimonial of data">
  <div class="item">
    <div class="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
      <div class="testimonial-text">
        <i class="ti-quote-left quote-icon text-gradient"></i>
        <p>{{testimonial.description}}</p>
      </div>
      <div class="testimonial-detail clearfix">
        <div class="testimonial-pic radius"><img src="assets/images/testimonial/{{testimonial.image}}" width="100" height="100" alt=""></div>
        <strong class="testimonial-name">{{testimonial.client_name}}</strong> <span class="testimonial-position">{{testimonial.designation}}</span> 
      </div>
    </div>
  </div>

    </ng-template>
</owl-carousel-o>
  
  <!-- <div class="item">
    <div class="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
      <div class="testimonial-text">
        <i class="ti-quote-left quote-icon text-gradient"></i>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.</p>
      </div>
      <div class="testimonial-detail clearfix">
        <div class="testimonial-pic radius"><img src="assets/images/testimonial/pic3.jpg" width="100" height="100" alt=""></div>
        <strong class="testimonial-name">David Matin</strong> <span class="testimonial-position">Manager</span> 
      </div>
    </div>
  </div>
  <div class="item">
    <div class="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
      <div class="testimonial-text">
        <i class="ti-quote-left quote-icon text-gradient"></i>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.</p>
      </div>
      <div class="testimonial-detail clearfix">
        <div class="testimonial-pic radius"><img src="assets/images/testimonial/pic2.jpg" width="100" height="100" alt=""></div>
        <strong class="testimonial-name">Monica Rodriguez</strong> <span class="testimonial-position">Client</span> 
      </div>
    </div>
  </div>
  <div class="item">
    <div class="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
      <div class="testimonial-text">
        <i class="ti-quote-left quote-icon text-gradient"></i>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.</p>
      </div>
      <div class="testimonial-detail clearfix">
        <div class="testimonial-pic radius"><img src="assets/images/testimonial/pic1.jpg" width="100" height="100" alt=""></div>
        <strong class="testimonial-name">Steve Tylor</strong> <span class="testimonial-position">photographer</span> 
      </div>
    </div>
  </div>
  <div class="item">
    <div class="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.0s">
      <div class="testimonial-text">
        <i class="ti-quote-left quote-icon text-gradient"></i>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.</p>
      </div>
      <div class="testimonial-detail clearfix">
        <div class="testimonial-pic radius"><img src="assets/images/testimonial/pic3.jpg" width="100" height="100" alt=""></div>
        <strong class="testimonial-name">David Matin</strong> <span class="testimonial-position">Manager</span> 
      </div>
    </div>
  </div>
  <div class="item">
    <div class="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.2s">
      <div class="testimonial-text">
        <i class="ti-quote-left quote-icon text-gradient"></i>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.</p>
      </div>
      <div class="testimonial-detail clearfix">
        <div class="testimonial-pic radius"><img src="assets/images/testimonial/pic2.jpg" width="100" height="100" alt=""></div>
        <strong class="testimonial-name">Monica Rodriguez</strong> <span class="testimonial-position">Client</span> 
      </div>
    </div>
  </div> -->
<!-- </div> -->