import { Component, OnInit } from '@angular/core';
import { $ } from 'protractor';
declare  var jQuery:  any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit(){
    (function ($) {
      // console.log('bassad')
      setTimeout(() => {
        if(jQuery('canvas').length > 0)
        {
          jQuery('canvas:not("#c")').remove();
            
        } 
      });
    })(jQuery);
  }
}
