<app-loading></app-loading>
<div class="page-wraper">
	<!-- header -->
    <app-header1 [data]="profile"></app-header1>
    <!-- header END -->
	<app-page-banner [data]="page_banner"></app-page-banner>
	<app-social-bar></app-social-bar>
	
	<div class="content-body">
		<div class="content-body-inner">
			<!-- About Image -->
			<!-- <div class="section-full wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
				<img src="assets/images/about/{{page_info.image}}" alt=""/>
			</div> -->
			
			<!-- About Info -->
			<div class="section-full content-inner-1">
				<div class="row align-items-center">
					<div class="col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
						<div class="section-head m-b0">
							<h2 class="title text-uppercase">{{page_info.title}}</h2>
							<p [innerHTML]="page_info.description" class="m-b0"></p>
						</div>
					</div>
					<div class="col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
						<ul class="personal-info">
							<!-- <li><span>AGE</span>{{member.age}}</li> -->
							<li><span>RESIDENCE</span>{{member.residence}}</li>
							<li><span>ADDRESS</span>{{member.address}}</li>
							<li><span>E-MAIL</span><a href="mailto:{{member.email}}">{{member.email}}</a></li>
							<li><span>PHONE</span><a href="tel:+972506868899">{{member.phone}}</a></li>
							<li><span>WHATSAPP</span><a target="_blank" href="https://wa.me/972506868899?text={{whatsAppMsg}}">{{member.phone}}</a></li>
							<li><span>LINKEDIN</span><a target="_blank" href="https://www.linkedin.com/in/abdo-qassem/">Abdo Qassem</a></li>
						</ul>
					</div>
				</div>
			</div>
			
			<!-- About Service -->
			<div class="section-full content-inner-1">
				<div class="section-head">
					<h2 class="title text-uppercase">My Services</h2>
				</div>
				<app-my-services [data]="services"></app-my-services>
			</div>
			<!-- Counter -->
			<div class="section-full content-inner-1">
				<div class="section-head">
					<h2 class="title text-uppercase">Proven Facts</h2>
				</div>
				<app-fun-fact-counter [data]="fun_facts"></app-fun-fact-counter>
			</div>
			<!-- Counter -->
			<!-- <div class="section-full content-inner-1">
				<div class="section-head">
					<h2 class="title text-uppercase">Testimonials</h2>
				</div>
				<app-testimonial [data]="testimonials"></app-testimonial>
			</div> -->
			<!-- Counter -->
			<!-- <div class="section-full content-inner-1">
				<div class="section-head">
					<h2 class="title text-uppercase">My Partners</h2>
				</div>
				<app-our-client [data]="our_clients"></app-our-client>
			</div> -->
		</div>
	</div>
</div>
