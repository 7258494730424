import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-web-developer-contact-us',
    templateUrl: './web-developer-contact-us.component.html',
    styleUrls: ['./web-developer-contact-us.component.css']
})
export class WebDeveloperContactUsComponent implements OnInit {

    contactFrom: FormGroup;

    loading: Subject<boolean> = new Subject();
    msg: string = '';
    msgStyle: 'successStyle' | 'errorStyle';
    showButton: boolean = true;
  
    profile : any = {
        logo:"logo-black-5.png",
        profile:"web-developer"
    };

    page_banner : any = {
        title:"Contact me",
        profile:"web-developer",
    };

    constructor(
        private _fb: FormBuilder,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.contactFrom = this._fb.group({
            name: [null, Validators.compose([
                Validators.required
            ])],
            email: [null, Validators.compose([
                Validators.required,
                Validators.email
            ])],
            phone: [null, Validators.compose([
                Validators.required,
                Validators.minLength(6)
            ])],
            company: [null],
            subject: [null, Validators.compose([
                Validators.required,
                Validators.minLength(6)
            ])],
            msg: [null, Validators.compose([
                Validators.required,
                Validators.minLength(10)
            ])]
        })
    }

    public inputHasError(conrtolName: string): boolean {
        return !this.contactFrom.controls[conrtolName].touched ? true : this.contactFrom.controls[conrtolName].valid;
    }

    sendMSG(){
        this.msg = '';
        if(this.contactFrom.valid){
            this.loading.next(true);
            this.http.post('https://formspree.io/f/xnqooypo', this.contactFrom.value).pipe(
                finalize(
                    () => {
                        this.loading.next(false);
                    }
                )
            ).subscribe(
                (res: any) => {
                    if(res.ok){
                        this.msgStyle = 'successStyle';
                        this.msg = 'Thanks for contacting me! Looking forward to talking to you ASAP!';
                        this.showButton = false;
                    }else{
                        this.msgStyle = 'errorStyle';
                        this.msg = 'Something went wrong. Please try again.';
                    }
                },
                (err) => {
                    this.msgStyle = 'errorStyle';
                    this.msg = 'Something went wrong. Please try again.';
                }
            )
        }else{
            this.contactFrom.markAllAsTouched();
            this.msgStyle = 'errorStyle';
            this.msg = 'Please fill in all required fields!';
        }
    }

}
