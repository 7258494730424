import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { arts } from 'src/assets/blog/arts';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  
  profile : any = {
                  logo:"logo-black.png",
                  profile:"photographer"
            };

  page_banner : any = {
        title:"Blog",
        profile:"photographer",
  };

  arts: any[] = arts;

  constructor(
    // public _http: HttpClient
  ) { }

  ngOnInit(): void {
    // const headerDict = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    // };
    // const requestOptions = {                                                                                                                                                                                 
    //   headers: new HttpHeaders(headerDict), 
    // };
    // this._http.get('https://blog.abdoqassem.com/', requestOptions).subscribe(
    //   (res) => {
    //     console.log(res);
        
    //   }
    // )
  }

  calcDelay(index: number){
    // console.log(index);
    
    // let adder = ((index + 1) / 3).toString();
    // let ms = 6;
    // if((index + 1) % 3 == 0){
    //   ms = 2;
    // }
    // if((index + 1) % 2 == 0){
    //   ms = 4;
    // }
    // let x = adder.split('.');
    // console.log(x);
    // console.log(ms.toString());
    
    // let finalNum = x[0] + '.' + ms.toString() + 's';
    // console.log(finalNum);
    
    // return finalNum;
  }

}
