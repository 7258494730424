<app-loading></app-loading>
<div class="page-wraper">
	<!-- header -->
    <app-header1 [data]="profile"></app-header1>
    <!-- header END -->
	<app-page-banner [data]="page_banner"></app-page-banner>
	<app-social-bar></app-social-bar>
	
	<div *ngIf="article" class="content-body">
		<div class="content-body-inner">
			<div class="section-full">
				<!-- blog start -->
                <div class="blog-post blog-single">
					<div class="dlab-post-meta">
						<ul>
							<li class="post-date"> {{article.date}} </li>
						</ul>
					</div>
					<div class="dlab-post-title">
                        <h1 class="post-title m-t0">{{article.title}}</h1>
                    </div>
                    <div class="dlab-post-text">
                        <p style="font-size: 22px;" [innerHTML]="article.opening">

                        </p>
                    </div>
                    <div class="dlab-post-media"> 
						<img [src]="article.image" alt=""> 
					</div>
                    <div class="dlab-post-text">
                        <ng-container *ngFor="let arP of article.content">
                            <h4 class="mt-5" *ngIf="arP.h2">{{arP.h2}}</h4>
                            <p *ngFor="let p of arP.p" [innerHTML]="p"></p>
                        </ng-container>
                         
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting  printer a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen  It has urvived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen It has urvived not only five centuries, but also the leap into electronic typesetting.</p>
                        <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Has been the industry's standard text ever since the 1500s, when an unknown printer took a galley.</blockquote>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen It has urvived not only five centuries, but also the leap into electronic typesetting.</p>
                        <h5>Completely Responsive</h5>
                        <img class="alignleft" width="300" src="assets/images/blog/grid/pic4.jpg" alt="">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only        five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releasefive centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
                        <div class="dlab-divider bg-gray-dark"></div>
                        <img class="alignright" width="300" src="assets/images/blog/grid/pic1.jpg" alt="">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p> -->
                    </div>
                    <div class="dlab-post-tags clear">
                        <div class="post-tags"> <a *ngFor="let tag of article.tags" href="javascript:void(0);">{{tag}} </a>  </div>
                    </div>
                    <blockquote *ngFor="let q of article.quote">{{q}}</blockquote>
                    
                </div>
                <!-- <div class="clear" id="comment-list">
                    <div class="comments-area" id="comments">
                        <h2 class="comments-title">3 Comments</h2>
                        <div class="clearfix">
                            <ol class="comment-list">
                                <li class="comment">
                                    <div class="comment-body">
                                        <div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonial/pic1.jpg" alt=""> <cite class="fn">Nashid Martines</cite> <span class="says">says:</span> </div>
                                        <div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. </p>
                                        <div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
                                    </div>
                                    <ol class="children">
                                        <li class="comment odd parent">
                                            <div class="comment-body">
                                                <div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonial/pic2.jpg" alt=""> <cite class="fn">Hackson Willingham</cite> <span class="says">says:</span> </div>
                                                <div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
                                                    In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
                                                    ac elementum ligula blandit ac.</p>
                                                <div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
                                            </div>
                                            <ol class="children">
                                                <li class="comment odd parent">
                                                    <div class="comment-body">
                                                        <div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonial/pic3.jpg" alt=""> <cite class="fn">Konne Backfield</cite> <span class="says">says:</span> </div>
                                                        <div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
                                                            In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
                                                            ac elementum ligula blandit ac.</p>
                                                        <div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
                                                    </div>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li class="comment">
                                    <div class="comment-body">
                                        <div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonial/pic1.jpg" alt=""> <cite class="fn">Nashid Martines</cite> <span class="says">says:</span> </div>
                                        <div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
                                            In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
                                            ac elementum ligula blandit ac.</p>
                                        <div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
                                    </div>
                                </li>
                                <li class="comment">
                                    <div class="comment-body">
                                        <div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonial/pic2.jpg" alt=""> <cite class="fn">Hackson Willingham</cite> <span class="says">says:</span> </div>
                                        <div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
                                            In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
                                            ac elementum ligula blandit ac.</p>
                                        <div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
                                    </div>
                                </li>
                                <li class="comment">
                                    <div class="comment-body">
                                        <div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonial/pic3.jpg" alt=""> <cite class="fn">Konne Backfield</cite> <span class="says">says:</span> </div>
                                        <div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
                                            In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
                                            ac elementum ligula blandit ac.</p>
                                        <div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
                                    </div>
                                </li>
                            </ol>
                            <div class="comment-respond" id="respond">
                                <h4 class="comment-reply-title" id="reply-title">Leave a Reply <small> <a style="display:none;" href="javascript:void(0);" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</a> </small> </h4>
                                <form class="comment-form" id="commentform" method="post" action="http://sedatelab.com/developer/donate/wp-comments-post.php">
                                    <p class="comment-form-author">
                                        <label for="author">Name <span class="required">*</span></label>
                                        <input type="text" value="author" name="author"  placeholder="Author" id="author">
                                    </p>
                                    <p class="comment-form-email">
                                        <label for="email">Email <span class="required">*</span></label>
                                        <input type="text" value="email" placeholder="Email" name="email" id="email">
                                    </p>
                                    <p class="comment-form-url">
                                        <label for="url">Website</label>
                                        <input type="text"  value="url"  placeholder="Website"  name="url" id="url">
                                    </p>
                                    <p class="comment-form-comment">
                                        <label for="comment">Comment</label>
                                        <textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                    </p>
                                    <p class="form-submit">
                                        <input type="submit" value="Post Comment" class="submit" id="submit" name="submit">
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> -->
			</div>
		</div>
	</div>
</div>