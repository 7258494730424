<ul class="knowledge-tag">

  <li *ngFor="let tag of data">{{tag}}</li>
  <!-- <li>iOS and android apps</li>
  <li>Create logo design</li>
  <li>Design for print</li>
  <li>Modern and mobile-ready</li>
  <li>Advertising services include</li>
  <li>Graphics and animations</li>
  <li>Search engine marketing</li> -->
</ul>