<div class="row">
  <div *ngFor="let item of data" class="col-xl-3 col-md-6 col-6 m-lg-b20">
    <div class="radial-progress-box">
      <svg class="radial-progress" [attr.data-percentage]="item.score" viewBox="0 0 80 80">
        <circle class="incomplete" cx="40" cy="40" r="35"></circle>
        <circle class="complete" cx="40" cy="40" r="35"  [style.stroke-dashoffset]="'39.58406743523136'"></circle>
        <text class="percentage" x="50%" y="58%" transform="matrix(0, 1, -1, 0, 80, 0)">{{item.score}}%</text>
      </svg>
      <div class="icon-content">
        <h5 class="title">{{item.title}}</h5>
      </div>
    </div>
  </div>
</div>